<template>
  <v-card outlined>
    <v-card class="elevation-0 pa-4">
      <v-row class="pa-4">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        />
        <v-card
          v-if="tipo_data != 'range'"
          outlined
          class="d-flex align-center ml-4"
          height="40px"
          style="overflow: hidden"
        >
          <v-btn
            @click="add_sub_date = '-1'"
            tile
            depressed
            height="40px"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-divider vertical class="mx-0"></v-divider>
          <v-menu
            ref="menu"
            v-model="menu_picker"
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                style="max-width: 130px"
                :value="dateFormated"
                class="text-body-2"
                append-icon="mdi-calendar"
                @click:append="menu_picker = true"
                solo
                flat
                dense
                readonly
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="changeDate"
              @change="dateChange"
              :type="tipo_data === 'dia' ? 'date' : 'month'"
              no-title
              scrollable
            >
            </v-date-picker>
          </v-menu>
          <v-divider class="mx-0" vertical></v-divider>
          <v-btn
            @click="add_sub_date = '1'"
            tile
            depressed
            height="40px"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card>
        <div
          v-if="tipo_data == 'range'"
          class="mx-4 d-flex align-center justify-center"
        >
          <DataField
            hide_details
            :data_sync.sync="data_inicio"
            :label="$tc('global.inicio')"
          />
        </div>
        <div
          v-if="tipo_data == 'range'"
          class="mx-4 d-flex align-center justify-center"
        >
          <DataField
            hide_details
            :data_sync.sync="data_fim"
            :label="$tc('global.fim')"
            :data_min="data_inicio"
          />
        </div>
        <v-btn-toggle v-model="tipo_data" class="ml-3">
          <v-btn
            value="dia"
            class="text-caption"
            height="40px"
          >
            {{ $tc("global.dia") }}
          </v-btn>
          <v-btn
            value="mes"
            class="text-caption"
            height="40px"
          >
            {{ $tc("global.mes") }}
          </v-btn>
        </v-btn-toggle>
        <v-card flat solo class="ml-3">
          <v-btn
            outlined
            class="text-caption"
            height="40px"
            @click="tipo_data = 'range'"
          >
            {{ $tc("global.livre") }}
          </v-btn>
        </v-card>
      </v-row>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="contasPagas"
      item-key="id"
      sort-by="id"
      sort-desc
      @click:row="openCancelamentoDialog"
      :items-per-page="10"
      class="data-tables mb-5"
    >
      <template v-slot:item.fatura_id="{ item }">
        <div class="flex" v-if="item.fatura_id">
          {{ item.fatura_ponto }}
          {{ item.fatura_numero.toString().padStart(7, "0") }}
        </div>
        <span v-else>{{ $tc("global.semFatura") }}</span>
      </template>
      <template v-slot:item.data_vencimento="{ item }">
        <div>
          <div v-if="item.data_vencimento">
            {{ item.data_vencimento | dateFormat("dd/MM/yyyy") }}
          </div>
        </div>
      </template>
      <template v-slot:item.valor="{ item }">
        <div
          class="d-flex justify-space-between"
          v-if="item.moeda_sigla == 'G$' && item.valor"
        >
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.valor | guarani }}</div>
        </div>

        <div class="d-flex justify-space-between" v-else-if="item.valor">
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.valor | currency }}</div>
        </div>
      </template>

      <template v-slot:item.juros="{ item }">
        <div
          class="d-flex justify-space-between"
          v-if="item.moeda_sigla == 'G$' && item.juros"
        >
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.juros | guarani }}</div>
        </div>
        <div class="d-flex justify-space-between" v-else-if="item.juros">
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.juros | currency }}</div>
        </div>
      </template>

      <template v-slot:item.desconto="{ item }">
        <div
          class="d-flex justify-space-between"
          v-if="item.moeda_sigla == 'G$' && item.desconto"
        >
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.desconto | guarani }}</div>
        </div>
        <div class="d-flex justify-space-between" v-else-if="item.desconto">
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.desconto | currency }}</div>
        </div>
      </template>

      <template v-slot:item.valor_pago="{ item }">
        <div
          class="d-flex justify-space-between"
          v-if="item.moeda_sigla == 'G$'"
        >
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.valor_pago | guarani }}</div>
        </div>
        <div class="d-flex justify-space-between" v-else-if="item.valor_pago">
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.valor_pago | currency }}</div>
        </div>
      </template>

      <template v-slot:item.saldo="{ item }">
        <div
          class="d-flex justify-space-between"
          v-if="item.moeda_sigla == 'G$' && item.saldo"
        >
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.saldo | guarani }}</div>
        </div>
        <div class="d-flex justify-space-between" v-else-if="item.saldo">
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.saldo | currency }}</div>
        </div>
      </template>

      <template v-slot:item.multa="{ item }">
        <div
          class="d-flex justify-space-between"
          v-if="item.moeda_sigla == 'G$' && item.multa"
        >
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.multa | guarani }}</div>
        </div>
        <div class="d-flex justify-space-between" v-else>
          <div>{{ item.moeda_sigla }}</div>
          <div>{{ item.multa | currency }}</div>
        </div>
      </template>

      <template v-slot:item.cliente_nome="{ item }">
        <v-tooltip bottom color="darkblue">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              {{ item.cliente_nome | excerpt(24) }}
            </div>
          </template>
          <span>{{ item.cliente_nome }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogCancelamento" width="500">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-alert</v-icon>
          {{ $tc("global.desejaCancelarConta") }}
        </v-card-title>
        <v-card-text>
          {{ $tc("global.avisoCancelamentoConta") }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" text @click="dialogCancelamento = false">
            {{ $tc("global.voltar") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="error"
            class="white--text"
            @click="efetuarCancelamento()"
          >
            <v-icon left>mdi-delete</v-icon>
            {{ $tc("global.sim") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  fetchContasReceber,
  cancelarContaReceber
} from "@/api/contas-receber/contas_receber.js";
import { mapGetters } from "vuex";
import { addDays, addMonths, format, parseISO, subDays, subMonths } from "date-fns";

export default {
  name: "ListContasReceberPagas",

  props: {},

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
  },

  data() {
    return {
      contasPagas: [],
      search: "",
      contaSelected: null,
      dialogCancelamento: false,
      data_inicio: null,
      data_fim: null,
      tipo_data: null,
      add_sub_date: null,
      menu_picker: false,
      changeDate: null,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          width: "40px",
          value: "id",
          divider: true,
        },
        {
          text: this.$tc("global.cliente"),
          
          value: "cliente_nome",
          divider: true,
        },
        {
          text: this.$tc("global.fatura"),
          width: "100px",
          align: "center",
          value: "fatura_id",
          divider: true,
        },
        {
          text: this.$tc("global.vencimento"),
          width: "110px",
          align: "center",
          value: "data_vencimento",
          divider: true,
        },
    
        {
          text: this.$tc("global.ordem"),
          width: "100px",
          align: "center",
          value: "ordem",
          divider: true,
        },
        {
          text: this.$tc("global.valor"),
          width: "100px",
          align: "right",
          value: "valor",
          divider: true,
        },
        {
          text: this.$tc("global.juros"),
          width: "100px",
          align: "right",
          value: "juros",
          divider: true,
        },

        {
          text: this.$tc("global.multa"),
          width: "100px",
          align: "right",
          value: "multa",
          divider: true,
        },

        {
          text: this.$tc("global.desconto"),
          width: "115px",
          align: "right",
          value: "desconto",
          divider: true,
        },
        {
          text: this.$tc("global.pago"),
          width: "110px",
          align: "right",
          value: "valor_pago",
          divider: true,
        },
        {
          text: this.$tc("global.saldo"),
          width: "110px",
          align: "right",
          value: "saldo",
        },
      ];
    },
    url() {
      let queryString = "?status=pagas";

      if (this.search && this.search.length > 3) {
        queryString += `&search=${this.search}`;
      }

      if (this.tipo_data!= undefined && this.tipo_data != "range") {
        queryString += `&tipo_data=${this.tipo_data}`;
        if (this.changeDate) {
          if(this.tipo_data == 'dia') {
            queryString += `&change_date=${format(parseISO(this.changeDate), "yyyy-MM-dd")}`;
          } else if (this.tipo_data == 'mes') {
            queryString += `&change_date=${format(parseISO(this.changeDate), "yyyy-MM")}`;
          }
        }
      }

      if (this.tipo_data != undefined && this.tipo_data == "range") {
        queryString += `&tipo_data=${this.tipo_data}`;
        if (this.data_inicio) {
          queryString += `&data_inicio=${this.data_inicio}`;
        }
        if (this.data_fim) {
          queryString += `&data_fim=${this.data_fim}`;
        }
      }

      return `${queryString}`;
    },

    dateFormated() {
      let result = "";

      if (!this.changeDate) {
        return "";
      }

      if (this.tipo_data === "dia") {
        result = format(parseISO(this.changeDate), "dd/MM/yyyy");
      }
      if (this.tipo_data === "mes") {
        result = format(parseISO(this.changeDate), "MM/yyyy");
      }

      return result;
    },

    now() {
      let result = "";

      if (this.tipo_data === "dia") {
        result = format(new Date(), "yyyy-MM-dd");
      }
      if (this.tipo_data === "mes") {
        result = format(new Date(), "yyyy-MM");
      }
      if (this.tipo_data === "range") {
        result = format(new Date(), "yyyy-MM-dd");
      }

      return result;
    },
  },

  watch: {
    data_inicio() {
      if (this.data_inicio > this.data_fim) {
        this.data_fim = this.data_inicio;
      }
    },
    data_fim() {
      if (this.data_fim < this.data_inicio) {
        this.data_fim = this.data_inicio;
      }
    },
    url() {
      this.fetchContasPagas();
    },
    add_sub_date() {
      if (this.add_sub_date) {
        if (this.add_sub_date === "-1") {
          if (this.tipo_data === "dia") {
            this.changeDate = format(
              subDays(parseISO(this.changeDate), 1),
              "yyyy-MM-dd"
            );
          } else {
            this.changeDate = format(
              subMonths(parseISO(this.changeDate), 1),
              "yyyy-MM"
            );
          }
        }
        if (this.add_sub_date === "1") {
          if (this.tipo_data === "dia") {
            this.changeDate = format(
              addDays(parseISO(this.changeDate), 1),
              "yyyy-MM-dd"
            );
          } else {
            this.changeDate = format(
              addMonths(parseISO(this.changeDate), 1),
              "yyyy-MM"
            );
          }
        }
      }
      this.add_sub_date = null;
    },
    tipo_data() {
      if (this.tipo_data === "dia") {
        this.changeDate = format(parseISO(this.changeDate), "yyyy-MM-dd");
      }
      if (this.tipo_data === "mes") {
        this.changeDate = format(parseISO(this.changeDate), "yyyy-MM");
      }

    },
  },

  methods: {
    async efetuarCancelamento() {
      await cancelarContaReceber(this.contaSelected.id)
        .then((response) => {
      
          this.$toast.success(this.$tc("global.cancelamentoEfetuado"));
          this.dialogCancelamento = false;
          this.fetchContasPagas();
        })
        .catch((error) => {
          console.log(error);
        }
      );
    },    
    openCancelamentoDialog(item) {
      this.dialogCancelamento = true;
      this.contaSelected = item;
    },
    async fetchContasPagas() {
      const url = this.url;
      await fetchContasReceber(url)
        .then((response) => {
          this.contasPagas = response;
          this.contasPagas.forEach((conta) => {
            if (conta.origem === "IMPORTACAO_DADOS") {
              conta.fatura_id = conta.historico;
              conta.fatura_numero = conta.historico;
              conta.fatura_ponto = conta.ponto_antigo;
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dateChange() {
      if (this.changeDate != this.now) {
        this.fetchContasPagas();
      }
    },
  },
  mounted() {
    this.tipo_data = 'mes';
    this.changeDate = this.now;
  },
};
</script>

<style></style>
